import * as React from 'react';
import '../styles/App.less';
import {setUTMCookie} from '../utils/CookieUtil';
import {PageProps} from 'gatsby';
import {useEffect} from 'react';
import config from '../components/config';
import {useAuth} from '../components/Auth/AuthContext';

const Logout = (props: PageProps) => {
  setUTMCookie(props);

  const {logout} = useAuth();

  useEffect(() => {
    logout({
      returnTo: config.auth0.logoutUri
    });
  });
  return null;
};

export default Logout;
